<template>
  <div class="address-list">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>收货地址</span>
      <span></span>
    </header>
    <main class="page-body">
      <!-- <div v-for="(item, index) in list" :key="index">
        {{ item.sAddr1 }}
      </div> -->
      <van-address-list
        v-model="chosenAddressId"
        :list="list"
        default-tag-text="默认"
        @add="onAdd"
        @edit="onEdit"
        @select="onSelect"
      />
    </main>
  </div>
</template>
<script>
import { getAddressList } from '@/api/account.js';
export default {
  data() {
    return {
      list: [],
      loading: false,
      chosenAddressId: '',
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    onAdd() {
      this.$router.push({ name: 'AddressEdit', query: { mode: 'add' } });
    },
    onEdit(row) {
      this.$router.push({
        name: 'AddressEdit',
        query: { mode: 'edit', ...row },
      });
    },
    onSelect(item) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          ...item,
        },
      });
    },
    loadData() {
      this.$toast.loading({ duration: 0 });
      getAddressList({})
        .then(res => {
          this.list = res.rows.map(item => {
            return {
              // ...item,
              id: item.PK_ID,
              name: item.sRecName,
              address: item.sAddr1,
              tel: item.sRecTel,
              email: item.sRecEmail,
              postalCode: item.sRecZipcode,
              addressDetail: item.sAddr1,
              province: item.sRecProvince,
              city: item.sRecCity,
              country: item.FK_RecCountryID,
              isDefault: item.bDefault,
            };
          });
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.page-body {
}
</style>
